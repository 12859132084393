export default () => {
  return [
    { id: 1, text: 'Psychologist' },
    { id: 2, text: 'Psychiatrist' },
    { id: 3, text: 'Licensed Mental Health Counselor (LMHC)' },
    { id: 4, text: 'Licensed Professional Counselor (LPC)' },
    { id: 5, text: 'Licensed Marraige and Family Therapist (LMFT)' },
    {
      id: 6,
      text: 'Licensed Clinical Alcohol & Drug Abuse Counselor (LCADAC)'
    },
    { id: 7, text: 'Licensed Clinical Social Worker (LCSW)' },
    { id: 8, text: 'Licensed Independent Clinical Social Worker (LICSW)' },
    { id: 9, text: 'Academy of Certified Social Worker (ACSW)' }
  ]
}
